.list-shifts {
	[class*="col-"] {
		margin-bottom: 15px;
	}

	.card {
		height: 100%;
		margin-bottom: 0;

		.form-group-periodos {
			.empty {
				display: block;
				padding: 2px 5px;
			}
		}

		.time-intervals {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.time-interval {
				background: $grey-light;
				border: 1px solid $grey-label;
				padding: 2px 5px;
				margin-right: 5px;
				margin-bottom: 5px;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}
}

.time-interval-disclaimer {
	font-size: 13px;
	margin-top: 5px;

	p {
		margin-bottom: 0;
	}

	ul {
		padding-left: 20px;
		margin: 10px 0;
		display: flex;

		li {
			list-style: disc;
			margin-right: 25px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.shift-description {
	display: block;
	margin-bottom: 15px;
	min-height: 20px;
}

.shift-edit {
	.time-intervals {
		background: $primary-lightest;
		padding: 10px;
		margin-bottom: 20px;

		.form-error {
			margin-top: -18px;
		}
	}

	.row-adding-days {
		.invalid-feedback {
			margin-bottom: -5px !important;
		}
	}

	.row-adding-help {
		// margin-top: -5px;
	}

	.btn-link {
		font-size: 90%;

		&:first-of-type {
			margin-right: 10px;
		}
	}

	.form-grupos {
		margin-bottom: 10px;
	}

	.form-time-type {
		max-width: 180px;
	}
}

.tooltip-shift {
	.heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	h2 {
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 0;
	}

	.shift-type {
		background: $primary;
		color: white;
		font-weight: 700;
		margin-left: 15px;
		padding: 1px 4px;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 0.3px;
		margin-right: -5px;
		// padding-right: 10px;
	}

	.subtitle {
		font-size: 14px;
		margin-bottom: 5px;
		// color: $primary;
	}

	.list-tags {
		li {
			padding: 2px 5px;
		}
	}

	.table {
		margin-bottom: 10px;

		th {
			font-size: 14px;
			font-weight: 700 !important;
			background-color: $primary-lighter !important;
		}

		th,
		td {
			padding: 5px;
			border: 1px solid $primary-lighter !important;
		}
	}
}

.tooltip-shift>.tooltip-inner {
	// background-color: $primary-lightest;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
	background-color: white;
	color: #000;
	border: 1px solid $primary-lightest;
	padding: 10px 15px;
	text-align: left;
}

.tooltip-shift .arrow::before {
	border-top-color: $primary;
}



// shift selector

.shift-selector-popover {
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
	border: 0;
	background: $grey-light;

	.popover-header {
		font-size: 14px;
		padding-top: 20px;
		background: $grey-light;
		padding-bottom: 0;
	}

	.popover-footer {
		display: none;
	}

	.shift-selector-popover-body {

		.form-search {
			margin-bottom: 10px;
		}
	}
}



// turno inicio y fin
.shift-times {
	font-size: 13px;

	.clock-icon {
		margin-right: -2px;
	}

	.separador {
		display: inline-block;
		width: 8px;
	}
}