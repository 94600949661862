.page-absences {
	.first-steps {
		// margin-top: 100px;
		margin: 0 0 20px 0;
		border: 1px solid $grey-disabled;
		max-width: 100%;
		padding: 15px;
		background: white;
	}

	.row-buttons {
		margin-right: -7.5px;
		margin-left: -7.5px;
		margin-bottom: 5px;

		[class*="col"] {
			padding-right: 7.5px;
			padding-left: 7.5px;
		}

		.btn {
			width: 100%;
			display: block;
			margin-bottom: 10px;
		}
	}
}

.answer-comments {
	background-color: $color-note !important;
}

.absence-answer-comments {
	background-color: $color-note;
	display: block;
	padding: 5px 10px;
	margin-top: 10px;
	margin-bottom: 15px;
}

.absence-edit {
	.form-group-time {
		width: auto;
	}

	.error-message {
		margin-top: 25px;
	}
}


.form-group-period {
	display: flex;
	align-items: center;
	// font-weight: 600;

	span {
		margin-top: 2px;
	}

	svg {
		font-size: 18px;
		margin-right: 5px;
	}

	.tag-dia:nth-child(2) {
		&:before {
			content: " - ";
		}
	}

	.absence-attachment {
		color: $grey-label;
	}

	.ausencia-attachments {
		display: flex;

		.dropzone-files {
			margin-top: 0;

			.file-info {
				max-width: 160px;

				.file-name {
					width: 100%;
				}
			}

			.file-item {
				justify-content: end;
			}

			span {
				margin-top: 0;
			}
		}
	}
}

.card-ausencia,
.row-ausencia {
	.ausencia-tipo {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 0;
	}

	.ausencia-resumen {
		color: $grey-label;

		span {
			color: black;
			font-weight: 500;
		}
	}

	.card-shift {
		padding: 4px 8px;
		margin-bottom: 5px;
		display: inline-block;
	}

	.absence-comments {
		min-height: 21px;
		display: block;
		margin-bottom: 5px;
	}

	.Pendiente {
		color: $color-orange;
	}

	.Denegado {
		color: $color-red;
	}

	.Aprobado {
		color: $color-green;
	}

	.Anulado {
		color: $grey;
	}

	.card-label-solicitado {
		margin-bottom: 0;
	}

	.card-label-gestionado {
		margin-bottom: 0 !important;
		color: $grey;
		font-size: 90%;

		.avatar {
			margin-right: 3px;
		}
	}
}

.row-ausencia {
	.form-group.no-form {
		margin-bottom: 0;
	}

	.ausencia-resumen {
		margin-bottom: 5px;
	}

	.form-group-period {
		.absence-attachment {
			display: none;
		}
	}
}

.table-mobile {
	.td-name-content {
		margin-bottom: 10px;
	}

	.estado {
		display: block;
	}
}

.header-widgets {
	margin-bottom: 10px;

	.widget-title {
		display: block;
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 8px;

		&.title-yellow {
			color: $color-yellow;
		}

		&.title-green {
			color: $color-green-dark;
		}

		&.title-red {
			color: $color-magenta;
		}

		&.title-blue {
			color: $color-blue;
		}

		&.title-orange {
			color: $color-orange;
		}

		&.title-purple {
			color: $primary-light;
		}

		&.title-blue-dark {
			color: $color-blue-dark;
		}

		&.title-grey {
			color: $grey;
		}
	}

	.widget-counter {
		font-weight: bold;
		font-size: 36px;
		display: block;
	}

	.icon-calendar-wrapper {
		background: $grey-light;
		padding: 10px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
	}

	.icon-calendar {
		color: $grey-label;
		width: 27px;
		height: 27px;
	}

	.widget-content {
		background: white;
		padding: 20px;
		margin-bottom: 15px;
	}

	.widget-main {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}


// calendar

.list-calendar {
	.column {
		margin-bottom: 30px;
	}

	.month {
		height: 100%;
		margin-bottom: 0;

		h3 {
			font-size: 18px;
			text-transform: capitalize;
			margin-bottom: 10px;
		}
	}
}

.table-calendar {
	width: calc(100% + 20px);
	margin: 0 -10px 0 -10px;
	//background: $grey-light;

	th,
	td {
		text-align: center;
		padding: 4px 1px;
	}

	th {
		padding-top: 10px;
		padding-bottom: 10px;
		color: $grey-label;
	}

	td {
		span {
			display: block;
			font-weight: 700;
			cursor: pointer;
			//border-bottom:1px solid transparent;
			//margin-left:5px;
			//margin-right:5px;
		}
	}
}