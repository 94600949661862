// placeholders
::-webkit-input-placeholder {
	/* Edge */
	color: $grey-medium !important;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: $grey-medium !important;
}

::placeholder {
	color: $grey-medium !important;
}

// input date
input[type="date"] {
	-webkit-appearance: none;

	&.empty {
		color: $grey-medium;
	}
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

// input range

input[type="range"]::-ms-thumb {
	background-color: $primary;
	width: 20px;
	height: 20px;
	border: 2px solid #ddd;
	border-radius: 50%;
}

input[type="range"]::-webkit-slider-runnable-track {
	background-color: #ccc;
	height: 2px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
	outline: none;
}

input[type="range"]::-moz-range-track {
	background-color: #ccc;
	height: 2px;
}

input[type="range"]::-ms-track {
	background-color: #ccc;
	height: 2px;
}

// ...

.form-group {
	margin-bottom: 25px;

	label,
	.label {
		color: $text-color;
		font-weight: 500;
		margin-bottom: 5px;

		&.form-check-label {
			font-weight: 400;
			margin-bottom: 0;
			margin-left: 3px;
		}
	}

	&.no-form {
		margin-bottom: 15px;

		label {
			color: $label-color;
			display: block;
			margin-bottom: 0;
			font-weight: 400;
		}
	}

	&.form-group-flex {
		display: flex;

		label {
			margin-bottom: 0;
			margin-right: 10px;
		}
	}
}



.form-group-flex.form-row-switch {
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;

	.form-label {
		font-weight: 400;
	}

	.switch {
		margin-top: -3px;
	}
}


select.form-control,
.select-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: white;
	background-image: url("../assets/icons/arrow-down.svg");
	background-repeat: no-repeat;
	background-position: calc(100% - 7px) 50%;
	background-size: auto 12px;
	padding-right: 25px;
	padding-left: 7px;

	&.is-invalid {
		background-color: white;
		background-image: url("../assets/icons/arrow-down.svg") !important;
		background-repeat: no-repeat;
		background-position: calc(100% - 7px) 50%;
		background-size: auto 12px;
	}
}

.form-control {
	border-color: $border-color;
	border-radius: 2px;
	font-size: 14px;
	padding: 8px 10px;
	height: 40px;

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(91, 73, 254, 0.15);
		border-color: rgba(91, 73, 254, 0.4);
	}

	&:disabled {
		background-color: $bg-disabled;
	}
}

// .form-control-file {

// }

// validations

.form-control.is-invalid~.invalid-feedback,
.input-group.is-invalid~.invalid-feedback {
	margin-bottom: -18px;
	margin-top: 2px;
	// text-align: right;
	line-height: 1.5em;
	display: block;
}

.label-required {
	color: $color-red;
	margin-left: 2px;
	font-size: 85%;
}



input[type="time"]::-webkit-calendar-picker-indicator {
	background: none;
	display: none;
}

.form-time {
	//width: 82px !important;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	padding: 0 0 0 1px !important;
	border-radius: 0;
	border: 1px solid transparent;
	border-bottom: 1px dotted $primary;
	color: $primary;
	background: transparent;
	min-height: 25px;
	max-width: 100%;
	min-width: 50px;
	//cursor: pointer;

	&:disabled {
		background: transparent;
		color: $primary-light;
		//cursor: not-allowed;
	}

	&.is-safari.is-empty {
		color: $primary-lighter;
	}

	// &:not(.has-value):not(.focus-visible) {
	// 	color: #EFEFF0;
	// }

	@media #{$max-xs} {
		font-size: 20px !important;
	}
}

.time-container {
	//width: 51px; //51
	display: inline-block;
	//overflow: hidden;
	margin-left: 3px;
	margin-right: 10px;
	max-width: 70px;
}

.form-md {
	max-width: 150px;
}

.form-date,
.form-color,
.form-group-time {
	position: relative;

	.form-control {
		cursor: pointer;
	}

	>svg {
		position: absolute;
		right: 5px;
		top: 35px;
		width: 25px;
		height: 20px;
	}
}

.form-group-side {
	display: flex;
	align-items: center;

	.form-label {
		margin-right: 10px;
	}
}

.form-date {
	>svg {
		background: white;
	}

	.is-invalid~svg {
		right: 30px;
	}

	input:disabled~svg {
		// background: transparent;
		background: $bg-disabled;
	}

	&.is-invalid {
		.form-control {
			border-color: $color-red;
		}

		.invalid-feedback {
			display: block;
		}
	}

	.react-datepicker-wrapper {
		display: block;
	}
}

.form-group-time {
	width: 85px;

	>svg {
		background: white;
		color: $grey-medium;
	}

	.form-control {
		&.is-safari.is-empty {
			color: $primary-lighter;
		}
	}
}

.form-label {
	font-weight: 500;
	margin-bottom: 5px;
}

.label-normal,
.form-group .label-normal {
	font-weight: 400;
}

.form-color {
	svg {
		color: $primary;
		cursor: pointer;

		&:hover {
			color: $primary-light;
		}
	}

	.form-label {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.icon-remove-color {}
	}

	.form-control[readonly] {
		background-color: transparent;
		color: black;

		&:focus-visible {
			box-shadow: none;
			border-color: $border-color;
		}
	}
}

.form-search {
	position: relative;

	svg {
		position: absolute;
		right: 5px;
		top: 8px;
		color: $grey-medium;
		width: 25px;
		height: 20px;
	}

	.btn-clear {
		position: absolute;
		right: 0;
		top: 0;
	}
}

.form-select-user {
	max-width: 175px;
}

// date picker:

.DateRangePicker {
	white-space: nowrap;
}

.DateRangePicker_picker,
.DateInput_fang {
	z-index: 10;
}

.DateRangePickerInput {
	&.DateRangePickerInput__withBorder {
		border-color: $border-color;
	}

	&.is-invalid {
		border-color: $color-red;
	}
}

.form-group.is-invalid {
	.DateRangePickerInput__withBorder {
		border-color: $color-red;
	}
}

.DateInput {
	width: 90px;

	.DateInput_input {
		line-height: 18px;
		font-size: 14px;
		padding: 11px 10px 8px 10px;
	}

	.DateInput_input__focused {
		border-bottom-color: $primary;
	}
}

.CalendarDay {
	&.CalendarDay__selected {
		background: $primary;
		border-color: $primary;
	}

	&.CalendarDay__selected_span {
		background: $primary-light;
		border-color: $primary-light;
	}

	&.CalendarDay__hovered_span {
		background: $primary-lighter;
		border-color: $primary-lighter;
		color: $primary-dark;
	}
}

.btn-choice-group {
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;

	.btn-choice {
		border: 1px solid $border-color;
		background-color: white;
		border-radius: 4px;
		position: relative;
		flex: 1 1 auto;
		cursor: pointer;
		min-height: 40px;
		display: inline-flex;
		align-items: center;

		&:first-child:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:not(:first-child) {
			margin-left: -1px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		input[type="checkbox"],
		input[type="radio"] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}

		&.active {
			background-color: $primary;
			border: 1px solid $primary;

			label {
				color: white;
			}
		}

		.form-check-label {
			padding: 8px 10px;
			display: block;
			cursor: pointer;
			width: 100%;
			text-align: center;
			color: $grey;
		}
	}

	&.disabled {
		.btn-choice {
			cursor: default;
			background-color: $bg-disabled;

			&.active {
				background-color: $primary-light;
				border: 1px solid $primary-light;
			}

			label,
			input {
				cursor: default;
			}
		}
	}
}

.btn-checkbox {
	background: white;
	border: 1px solid $primary;
	flex: 1 1 auto;
	cursor: pointer;
	min-height: 40px;
	display: flex;
	align-items: center;
	position: relative;
	color: $grey; // TODO mirar si es pot passar per parametre a contrastColor perque sigui el default

	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		pointer-events: none;
	}

	.btn-check-label {
		padding: 8px 10px;
		display: block;
		cursor: pointer;
		width: 100%;
		text-align: center;
		margin-bottom: 0;
		color: inherit;
	}

	&:hover {
		background: $primary-lighter;
	}

	&.active {
		background-color: $primary;
		border: 1px solid rgba(0, 0, 0, 0.4);

		label {
			color: white;
		}

		&:hover {
			background: $primary-dark;
		}
	}
}

// checkboxes

.radio,
.checkbox {

	input[type="radio"],
	input[type="checkbox"] {
		margin: 0;
		width: 16px;
		height: 16px;
		opacity: 0;

		&:hover+label:after {
			color: $grey-medium;
		}

		&:checked+label:after {
			color: white;
		}

		// $grey
		&:checked+label:before {
			background-color: white;
			border: 1px solid $primary; //$grey-2
		}
	}

	input[type="checkbox"] {
		&:checked+label:before {
			background-color: $primary;
		}
	}

	label {
		position: relative;
		padding-left: 35px;
		user-select: none;
		min-height: 20px;

		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 20px;
			height: 20px;
			//font-family: $f-icons;
			text-align: center;
			vertical-align: middle;
			color: $grey-medium;
		}

		&:before {
			border: 1px solid $border-color;
			background: rgba($grey-light, 0.48);
		}

		&:empty {
			padding-left: 0;
			width: 20px;
		}

		&:hover {
			cursor: pointer;
		}

		.form-group & {
			font-weight: 600;
		}
	}

	input[type="radio"] {
		&:hover+label:after {
			background-color: $grey-medium;
		}

		&:checked+label:after {
			background-color: $primary;
		}

		+label {

			&:before,
			&:after {
				border-radius: 50%;
			}

			&:after {
				left: 4px;
				width: 8px;
				height: 8px;
			}
		}
	}

	input[type="checkbox"] {

		&:hover,
		&:checked {
			+label:after {
				content: "\2714";
				font-size: 12px;
				line-height: 20px;
			}
		}

		+label:before {
			border-radius: 3px;
		}

		&:disabled+label:before {
			background-color: $grey-light;
		}

		&:disabled:checked+label:before {
			background-color: $primary-lighter;
			border-color: $primary-lighter;
		}
	}

	&.checkbox {
		.radio {
			&:checked+label:after {
				width: 1.516em;
				height: 1.516em;
				font-size: 12px;
				line-height: 1.516em;
			}

			+label:before {
				border-radius: 50%;
			}
		}
	}
}

// flex group

.flex-group {
	display: flex;
	align-items: center;
	align-items: flex-end;
	margin-bottom: 25px;

	.form-group {
		width: 100%;
		margin-right: 15px;
		margin-bottom: 0;
	}
}

.row-adding {
	margin-bottom: 25px;

	.flex-group {
		margin-bottom: 10px;
	}
}

input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

// input number (used for + / -)

.rc-input-number {
	margin: 0;
	padding: 0;
	transition: all 0.3s;
}

.rc-input-number-input {
	width: 100%;
	text-align: center;
	outline: 0;
	-moz-appearance: textfield;
	line-height: 26px;
	height: 100%;
	transition: all 0.3s ease;
	border: 0;
	border-radius: 4px;
	padding: 0;
	transition: all 0.3s;
	background: transparent;
}

.rc-input-number-input-wrap {
	overflow: hidden;
	height: 100%;
}

.rc-input-number-handler {
	height: 50%;
	display: flex;
	cursor: pointer;
	transition: all 0.3s;
	justify-content: center;

	&:hover {
		background-color: $primary-lightest;
	}
}

.rc-input-number-handler-wrap {
	float: right;
	width: 16px;
	height: 100%;
	transition: all 0.3s;
	font-weight: 700;
}

.rc-input-number-handler-up-inner,
.rc-input-number-handler-down-inner {
	height: 100%;
	width: 100%;
	text-align: center;

	&:after {
		content: "";
		display: inline-block;
		border-right: 2px solid $grey;
		border-bottom: 2px solid $grey;
		width: 5px;
		height: 5px;
	}
}

.rc-input-number-handler-up-inner {
	&:after {
		transform: rotate(-135deg);
	}
}

.rc-input-number-handler-down-inner {
	&:after {
		transform: rotate(45deg);
		margin-bottom: 7px;
	}
}

// form error message

.form-error {
	color: $color-red;
	font-size: 80%;
}

.error-message {
	color: $color-red;
	margin-top: 15px;
}

.error-box {
	p {
		margin-bottom: 7px;
	}
}

.list-error {
	li {
		list-style: initial;
		margin-left: 15px;
	}
}

// react datepicker

.react-datepicker {
	font-family: $theme-font;
}

.react-datepicker__header {
	background-color: $primary-lightest;
	border-bottom: $primary;
}

.react-datepicker__navigation {
	top: 7px;
}

.react-datepicker__navigation-icon::before {
	border-color: $primary-light;
}

.react-datepicker__day--selected {
	background: $primary;

	&:hover {
		background: $primary-dark;
	}
}




// media queries

@media #{$max-xs} {

	input[type="color"],
	input[type="date"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="email"],
	input[type="month"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="tel"],
	input[type="text"],
	input[type="time"],
	input[type="url"],
	input[type="week"],
	select:focus,
	textarea {
		font-size: 16px;
	}

	.form-control {
		height: 50px;
	}

	.form-date {
		>svg {
			top: 40px;
		}
	}

	.form-search {
		>svg {
			top: 15px;
		}
	}
}


.col-right-separator {
	position: relative;

	&:after {
		content: "";
		width: 30px;
		display: block;
		border-right: 1px dashed $primary-light;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
	}
}